import {DropzoneDialog} from "mui-file-dropzone";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormLabel,
    MenuItem,
    Select
} from "@mui/material";
import {useRef, useState} from "react";
import {FileUpload} from "@mui/icons-material";
import readXlsxFile from "read-excel-file";
import toast from "react-hot-toast";
import {isEmpty, uniq} from "lodash";
import Row from "../row/Row";

const FileUploader = (props) => {
    const setRowsReal = props.setRows

    const [open, setOpen] = useState(false)
    const [rows, setRows] = useState([])
    const [filename, setFilename] = useState('')
    const imageColumn = useRef()

    const resolveRows = (rows, name) => {
        if (rows.length < 2) {
            toast.error("Пустой файл!")
            return
        }
        setFilename(name)
        setRows(rows)
    }

    const selectAnother = () => {
        setRows([])
        setFilename('')
        setOpen(true)
    }

    const resetFile = () => {
        setRows([])
        setOpen(false)
    }

    const save = () => {
        const image = imageColumn.current.value
        if (isEmpty(image)) {
            toast.error('Вы ничего не выбрали')
            return
        }
        const headers = rows[0]
        rows.splice(0, 1)
        const nameIndex = headers.indexOf('Наименование')
        console.log(nameIndex)
        let idOffset = 1
        /*if (nameIndex !== -1) {
            const category = countBy(rows, it => (it[nameIndex] === "Unsorted" || it[nameIndex].startsWith('!')) ? '1' : '0')['1']
            if (!isNaN(category)) {
                rows.splice(0, category)
                idOffset += category
            }
        }*/

        const imagesIndices = headers
            .map((it, i) => [it, i])
            .filter(([it]) => it === image)
            .map(([_, it]) => it)
        const rowsObj = rows.map((row, i) => {
                const images = imagesIndices.map(i => row[i])
                    .filter(it => !isEmpty(it))
                row.splice(imagesIndices[0], 100)
                return new Row(i + idOffset, row, images)
            }
        )
        setRowsReal(rowsObj, headers.filter(it => it !== image), image, filename.replace('.xlsx', '-edited.xlsx'))

        setOpen(false)
        setRows([])
    }

    return [
        <Dialog key='image-column-dialog' open={rows.length > 0} onClose={resetFile}>
            <DialogTitle>Выберите колонку изображений</DialogTitle>
            <DialogContent>
                <FormControl>
                    <FormLabel component="legend">Колонка изображений:</FormLabel>
                    <Select inputRef={imageColumn} defaultValue='' variant='standard'>
                        <MenuItem value=''>Нет</MenuItem>
                        {uniq(rows[0]).map(it => <MenuItem key={it} value={it}>{it}</MenuItem>)}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={selectAnother}>Выбрать другой файл</Button>
                <Button onClick={save}>Сохранить</Button>
            </DialogActions>
        </Dialog>,
        <DropzoneDialog key='dropzone' maxFileSize={104857600}
                        acceptedFiles={['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
                        onClose={() => setOpen(false)}
                        onSave={file => {
                            setOpen(false)
                            readXlsxFile(file[0]).then(rows => resolveRows(rows, file[0].name))
                        }}
                        clearOnUnmount={true} filesLimit={1}
                        dialogTitle='Выберите файл'
                        dropzoneText='Переместите файл сюда'
                        open={open}/>,
        <Button key='btn' onClick={() => setOpen(true)} startIcon={<FileUpload/>}>Выберите файл c товарами</Button>
    ]
}
export default FileUploader
