class Row {
    constructor(id, cells, images) {
        this.id = id;
        this.cells = cells;
        this.images = images;
    }

    changed = false

    addImage(src) {
        this.images.push(src)
    }

    removeImage(src) {
        const index = this.images.indexOf(src)
        if (index === -1) {
            return
        }
        this.images.splice(index, 1)
    }
}

export default Row
