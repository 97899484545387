import {Grid, IconButton} from "@mui/material";
import {Remove} from "@mui/icons-material";

const Image = ({src, remove}) => {
    return <Grid item>
        <IconButton onClick={()=>remove(src)} key='remove' size='small' style={{
            position: 'absolute',
            float: 'right',
            backgroundColor: 'white',
            opacity: '0.5',
            width: '25px',
            height: '25px'
        }}>
            <Remove/>
        </IconButton>
        <img key='img' style={{maxHeight: '90px'}} src={src}/>
    </Grid>
}
export default Image
