import {maxBy} from "lodash";
import writeXlsxFile from "write-excel-file";
import {saveAs} from 'file-saver';

function isValidHttpUrl(string) {
    let url;
    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
}

const downloadExcel = (_headers, rows, imageColumn, filename, onlyChanged) => {
    let headers = [..._headers]
    const maxImages = maxBy(rows, it => it.images.length).images.length
    for (let i = 0; i < maxImages; ++i) {
        headers.push(imageColumn)
    }
    headers = headers.map(it => ({value: it, fontWeight: 'bold'}))
    if (onlyChanged) {
        rows = rows.filter(it => it.changed)
    }
    rows = rows.map(it => {
        const cells = [...it.cells]
        cells.push(...it.images)
        return cells.map(it => ({value: it}))
    })
    const data = [headers, ...rows]
    writeXlsxFile(data, {fileName: filename}).then(file => saveAs(file, filename))
}

export {isValidHttpUrl, downloadExcel}
